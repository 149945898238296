@import "../../styles/variables";
$width: 47%;
$height: 152px;
$heightSM: 225px;
$heightLG: 275px;
$gutter: 16px;

@media (max-width: 991px) {
  .close-icon {
    width: 32px;
    height: 32px;
    top: -40px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 999px;
    z-index: 90;
  }
  .height-auto {
    height: auto;
  }
  .vhide {
    display: none;
  }
  .vshow {
    display: flex;
  }
  .modal {
    height: auto;
    top: 140px;
    .selected-title {
      margin: 16px 0 4px 0;
      text-align: center;
    }
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
    .description,
    .validity {
      text-align: center;
    }
  }
  .modal-padding {
    padding: 16px;
  }
  .navbar {
    padding: 19px 16px;
    .navbar-brand img {
      max-height: 43px;
    }
  }
  .hooray {
    margin-top: 14px;
  }

  .float-label .input-send-copy {
    width: 100%;
  }
  .btn-send {
    width: 100%;
    // height: 36px;
  }
  .grid-item {
    // width:$width;
    // max-height: $height;
    margin-right: 8px;
    margin-bottom: $gutter;

    .title-block {
      height: 44px;
      padding: 12px 10px;
      h2 {
        margin: 0;
      }
    }

    &:nth-child(even) {
      // left: $width;
      // margin-left: $gutter;
      margin-right: 0;
    }

    .close {
      left: 47%;
    }

    .card_logo {
      // padding-top: 12px;
      // padding-left: 12px;
      width: 100%;
      height: auto;
      img {
        height: 104px;
      }
    }

    &.selectedBlock {
      width: 100%;
      left: 0%;
      min-height: 520px;
      top: 10px;
      margin: 0;
      padding: 16px;
      .description {
        min-height: 100px;
        max-height: 100px;
      }
      .card-parent {
        display: block;
      }

      .front_img {
        // width: 120px;
        // bottom: 24%;
      }
      .card_logo {
        width: 180px;
        height: 120px;
        margin: auto;
        img {
          width: 100%;
          border-radius: $borderRadius2x;
        }
      }
      .title-block h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: $inkNormal;
        display: block;
      }
      h2 {
        font-size: 16px;
        font-weight: 500;
        color: $inkNormal;
        text-align: center;
      }

      .validity {
        font-size: 14px;
        color: $inkLight;
        text-align: center;
      }
    }
  }

  .secure-pin {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .title-block {
    .description {
      width: 100%;
      font-size: 14px;
      color: $inkLight;
      max-height: 90px;
      // overflow: scroll;
      line-height: 20px;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
    }
    h2.price {
      font-size: 19px;
    }
  }
  .collect_voucher_btn {
    width: 86%;
    .btn {
      width: 100%;
      padding: 16px;
      position: static;
      height: 56px;
      background: $inkNormal;
      color: #fff;
    }
  }
  .front_img {
    bottom: 10%;
    right: 10px;
    width: 90px;
  }
  .mob-center {
    justify-content: left;
  }

  .voucher-code {
    /* margin-left: 10px; */
    max-width: 100%;
    width: 480px;
  }
  .front_img_full_page {
    width: 44%;
    display: none;
  }

  .front_img_full_page_mobile {
    text-align: right;
    img {
      width: 70%;
    }
  }
  .modal-body {
    height: unset;
  }

  .cardBlock {
    top: 10px;
    // margin-top: 16px;

    .blocks {
      width: 100%;
      // height: 97vh;
    }
  }
  .brand-title {
    width: 98%;
  }
  .tnc-card {
    // top:20px;
    padding: 16px;
    height: 84vh;
    .tnc-content {
      height: 84%;
    }
    .btn-lg {
      left: 18%;
    }
  }
  .btn-lg {
    font-size: 16px;
  }
  .link {
    font-size: 14px;
  }

  .modal-dialog {
    margin-top: 0;
  }
  .modal-wrapper {
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
  .modal-wrapper.error-modal {
    margin: 80px 16px;
    border-radius: 4px;
    height: auto;
  }
  .modal-wrapper.other-modal {
    margin: 80px 16px;
    border-radius: 4px;
    height: auto;
    .modal-content {
      height: 100%;
      overflow: auto;
    }
  }

  .send-code-wrap {
    height: 100%;
    width: 100%;
    margin-top: 90px;
  }
  .tnc-wrap {
    width: 100%;
    height: 100%;
    .tnc-block {
      width: 100%;
      height: 84vh;
      padding: 16px 8px;
    }
  }
  .tnc-cardModal {
    height: 84%;
  }
  .send-copy-btn {
    width: 100%;
  }
}

@media (max-width: 667px) {
  .grid-item {
    width: $width;
    max-height: $height;
    margin-right: 8px;
    margin-bottom: $gutter;
  }
  .mob-center {
    padding-left: 10px;
    padding-bottom: 2rem;
    justify-content: left;
  }
}
@media (max-width: 321px) {
  .grid-item {
    width: 140px;
    max-height: $height;
    margin-right: $gutter;
    margin-bottom: $gutter;
  }
}
