@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@charset "UTF-8";
/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	TRANSITIONS
------------------------------------------------------------------------------------------------------- */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  color: #252a31; }

h1 {
  font-size: 36px;
  line-height: 28px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin-top: 1rem; }

h3 {
  font-size: 16px;
  line-height: 31px; }

h4 {
  font-size: 17px;
  line-height: 19px; }

h5 {
  font-size: 16px; }

p {
  line-height: 24px; }

span.primary {
  color: #66286b; }

a.primary {
  color: #66286b; }

a {
  color: #808080; }
  a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #808080; }

.ink-lighter {
  color: #bac7d5; }

.inkLight {
  color: #5f738c; }

.inkLighter {
  color: #bac7d5; }

.inkNormal {
  color: #252a31; }

.colorDark {
  color: #000000; }

.text-lg {
  font-size: 21px; }

.title {
  color: #000000; }

.bodyText {
  color: #646464; }

strong {
  font-weight: 500; }

.brand-color {
  color: #66286b; }

.text-black {
  color: #000000; }

.text-color {
  color: #fff; }

.link {
  color: #66286b;
  cursor: pointer; }

ul {
  padding-left: 0px; }
  ul li {
    list-style: none; }

.cursor-pointer {
  cursor: pointer; }

body {
  font-size: 16px;
  color: #646464;
  background-color: #201f38;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding-right: 0;
  line-height: 24px;
  overflow-y: scroll;
  position: relative; }

.showOneline,
.showOneLine span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }

.showTwoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.width-50 {
  width: 50%; }

.border-radius {
  border-radius: 7px; }

.box-shadow {
  box-shadow: 0px 0 20px 5px rgba(209, 209, 209, 0.38); }

.svg-icon {
  width: 16px;
  margin-right: 10px;
  margin-top: 10px; }

.svg-white {
  filter: brightness(0) invert(1); }

/* =====================================

	BUTTONS

================================================================================================================ */
.btn {
  padding: 5px 15px;
  border: 1px solid #542158;
  color: #ffffff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important; }
  .btn.btn-outline-default {
    border: 1px solid #252a31;
    border-radius: 4px;
    color: #252a31;
    padding: 8px 16px; }
    .btn.btn-outline-default:hover {
      background: #252a31;
      color: #fff; }
      .btn.btn-outline-default:hover svg path {
        fill: #fff; }
  .btn.btn-primary {
    border: 1px solid #542158;
    color: #ffffff;
    border-radius: 23px;
    font-size: 13px;
    background-color: #66286b;
    border-color: transparent; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:active:hover, .btn.btn-primary:active:focus {
      background-color: #fff;
      color: #66286b;
      border: 1px solid #66286b;
      box-shadow: none !important;
      cursor: pointer; }
  .btn.btn-secondary {
    border: 1px solid #42b9c3;
    background-color: #55c0c9;
    color: #ffffff; }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary:active:hover, .btn.btn-secondary:active:focus {
      background-color: #42b9c3; }
  .btn.btn-default {
    border: 1px solid #818181;
    border-radius: 4px;
    background-color: #252a31;
    color: #ffffff;
    padding: 8px 16px 8px 16px;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px; }
    .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active, .btn.btn-default:active:hover, .btn.btn-default:active:focus {
      background-color: #818181; }
  .btn .btn-send {
    width: 80px; }
  .btn.btn-transparent {
    border: none;
    color: #646464;
    background-color: transparent; }
    .btn.btn-transparent.btn-border {
      border: 1px solid #e3e3e3; }
    .btn.btn-transparent.btn-border-primary {
      border: 1px solid #66286b;
      color: #66286b; }
      .btn.btn-transparent.btn-border-primary:hover {
        background: #66286b;
        color: #ffffff; }
  .btn.btn-lg {
    padding: 10px 20px; }
  .btn.btn-md {
    padding: 6px 20px; }
  .btn.btn-sm {
    padding: 0px 6px;
    font-size: 12px; }
  .btn.btn-xs {
    padding: 2px 4px; }
  .btn.btn-has-icon {
    position: relative;
    top: 10px;
    left: 18px; }
    .btn.btn-has-icon i {
      font-size: 30px;
      position: relative;
      top: 8px; }
    .btn.btn-has-icon:hover i {
      color: #66286b;
      font-weight: 500;
      right: -5px; }
  .btn.btn-social.btn-facebook {
    background: #3b5998;
    border-color: #344e86; }
    .btn.btn-social.btn-facebook .icon {
      border-right: 1px solid #4c70ba; }
  .btn.btn-social.btn-google {
    background: #ea4335;
    border-color: #e82e1e; }
    .btn.btn-social.btn-google .icon {
      border-right: 1px solid #ef6e63; }
  .btn.btn-social .icon {
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    left: 0;
    color: #ffffff; }

.btn-primary[disabled]:hover {
  background: #66286b;
  color: #fff; }

.btn-wrapper {
  margin: 40px 0; }

/* =====================================

	FORM INPUTS

================================================================================================================ */
.form-group {
  margin-bottom: 20px; }
  .form-group .input-error {
    font-size: 12px;
    display: block;
    padding: 1px 5px;
    font-weight: 600;
    color: #f44336; }

.form-check-input {
  margin-left: 0; }

.form-control {
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  box-shadow: none !important;
  font-size: 16px;
  min-height: 42px;
  margin-right: 0; }
  .form-control:focus {
    box-shadow: none;
    outline: none;
    border-color: #66286b; }
  .form-control + .SingleDatePicker {
    position: relative;
    top: -43px;
    width: 98%;
    left: 1%; }
    .form-control + .SingleDatePicker .DateInput--with-caret:before {
      top: 45px; }
    .form-control + .SingleDatePicker .DateInput--with-caret:after {
      top: 46px; }
    .form-control + .SingleDatePicker .SingleDatePicker__picker {
      top: 53px;
      left: -3px; }
  .form-control.input-sm {
    min-height: 26px; }

select.input-lg {
  height: 46px;
  line-height: 1; }

.rotate {
  transform: rotate(360deg) !important; }

/***** INPUT *****/
input[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio; }

input[type="checkbox"]:checked:before {
  top: 5px;
  left: -10px;
  width: 18px;
  height: 20px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #66286b;
  border-bottom: 2px solid #66286b;
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  transform-origin: 100% 100%; }

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0px;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #a9abae;
  border-radius: 1px;
  margin-top: 0px;
  transition: 0.2s;
  background: #fff; }

.select-dropdown {
  position: relative;
  width: auto;
  display: inline-block;
  max-width: 100%;
  border-radius: 2px;
  border: 0px solid #ccc;
  margin-right: 4px; }
  .select-dropdown select {
    width: 100%;
    padding: 2px 15px 0px 1px;
    height: 34px;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: url("/img/icons/down-arrow.svg") 98%/11px no-repeat transparent; }

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */ }

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9; } }

/* ---------------------------
	PLACEHOLDERS
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	LABEL
------------------------------------------------------------------------------------------------------- */
label {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px; }

.input-title {
  font-size: 16px;
  font-weight: 500;
  margin-left: 0; }
  .input-title.title-lg {
    font-size: 21px;
    font-weight: 300; }

.input-error {
  font-size: 13px;
  color: #f44336; }

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 0px; }

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border: 2px solid #9744a2;
  -moz-border: 2px solid #9744a2;
  -ms-border: 2px solid #9744a2;
  border: 2px solid #9744a2;
  border-radius: 50%; }

input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border: 2px solid #9744a2;
  -moz-border: 2px solid #9744a2;
  -ms-border: 2px solid #9744a2;
  border: 2px solid #9744a2;
  border-radius: 50%; }
  input[type="radio"]:checked:focus {
    outline: none; }

input[type="radio"]:checked:before {
  background: #9744a2;
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; }

.float-label input {
  width: 100%;
  height: 58px;
  padding: 16px 16px 0 12px;
  outline: 0;
  border: 1px solid #bac7d5;
  border-radius: 4px;
  background: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #252a31; }

.float-label label {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  padding: 0 12px;
  color: #bac7d5;
  pointer-events: none;
  position: absolute;
  top: -10px;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-weight: 400; }

.float-label:focus-within label {
  transform: translate(0, 17px) scale(0.8);
  color: #5f738c; }

.float-label .active {
  transform: translate(0, 17px) scale(0.8); }

.float-label .input-send-copy {
  width: 480px;
  height: 58px;
  border: 1px solid #bac7d5; }

.md-checkbox {
  position: relative;
  margin: 0.5em 0;
  text-align: left; }
  .md-checkbox.md-checkbox-inline {
    display: inline-block; }
  .md-checkbox label {
    cursor: pointer;
    display: inline;
    line-height: 1.25em;
    vertical-align: top;
    clear: both;
    padding-left: 1px; }
    .md-checkbox label:not(:empty) {
      padding-left: 0.5em; }
    .md-checkbox label:before, .md-checkbox label:after {
      content: "";
      position: absolute;
      left: 0;
      top: 4px; }
    .md-checkbox label:before {
      width: 1.25em;
      height: 1.25em;
      background: #fff;
      border: 2px solid #707070;
      border-radius: 0.125em;
      cursor: pointer;
      transition: background 0.3s; }
  .md-checkbox input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit; }
    .md-checkbox input[type="checkbox"]:checked + label:before {
      background: #66286b;
      border: none; }
    .md-checkbox input[type="checkbox"]:checked + label:after {
      transform: translate(0.25em, 0.33654em) rotate(-45deg);
      width: 0.75em;
      height: 0.375em;
      border: 0.125em solid #fff;
      border-top-style: none;
      border-right-style: none; }
    .md-checkbox input[type="checkbox"]:disabled + label:before {
      border-color: #8e8e8e; }
    .md-checkbox input[type="checkbox"]:disabled:checked + label:before {
      background: rgba(0, 0, 0, 0.26); }

@media (min-width: 992px) {
  .voucher__block {
    width: 75% !important;
    margin: 0 auto;
    justify-content: center !important; } }

@media (min-width: 992px) {
  .voucher__block2 {
    width: 100% !important;
    margin: 0 auto;
    justify-content: left !important; } }

.voucher__input input {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #252a31;
  border: 1px solid #bac7d5;
  border-radius: 4px;
  padding: 7px 15px; }
  @media (max-width: 992px) {
    .voucher__input input {
      font-size: 12px; } }
  .voucher__input input:focus {
    border-color: #252a31; }

.-label {
  cursor: auto !important;
  display: block;
  margin-bottom: 0 !important; }

@media (max-width: 992px) {
  .css-1uccc91-singleValue {
    font-size: 11px !important; } }

@media (max-width: 992px) {
  .css-tlfecz-indicatorContainer {
    padding: 3px !important; } }

@media (max-width: 992px) {
  .css-tlfecz-indicatorContainer svg {
    width: 16px !important; } }

.input-group-append {
  height: 34px; }
  .input-group-append .input-group-text {
    padding: 8px 12px 8px 8px;
    border: unset;
    font-size: 14px;
    background-color: transparent;
    color: #5f738c; }
    @media (max-width: 992px) {
      .input-group-append .input-group-text {
        font-size: 12px; } }

.inputError {
  border: 1px solid #d21c1c;
  background-color: #faeaea; }

.-countryCode {
  text-align: left !important;
  width: 32%;
  color: #252a31; }
  .-countryCode :focus {
    border: none !important; }
  @media (max-width: 992px) {
    .-countryCode {
      width: 50%; } }

.inputErrorOnly {
  border: 1px solid #bac7d5;
  color: #252a31;
  min-height: 40px;
  height: 40px;
  border: unset;
  color: #252a31;
  font-size: 14px; }
  @media (max-width: 992px) {
    .inputErrorOnly {
      font-size: 12px;
      min-height: 34px;
      height: 34px; } }
  .inputErrorOnly::-webkit-input-placeholder {
    color: #5f738c; }
  .inputErrorOnly::-moz-placeholder {
    color: #5f738c; }
  .inputErrorOnly :-ms-input-placeholder {
    color: #5f738c; }
  .inputErrorOnly :-moz-placeholder {
    color: #5f738c; }
  .inputErrorOnly.error {
    border: 1px solid #d21c1c;
    background-color: #faeaea; }

.-phnError {
  color: #d21c1c;
  text-align: left;
  font-size: 14px;
  line-height: 24px; }
  @media (max-width: 992px) {
    .-phnError {
      font-size: 12px;
      line-height: 22px; } }

.css-1m09ebu-control,
.css-djwo0a-control {
  min-height: 40px !important;
  height: 40px !important; }
  @media (max-width: 992px) {
    .css-1m09ebu-control,
    .css-djwo0a-control {
      min-height: 34px !important;
      height: 34px !important; } }

.css-1m09ebu-control:focus {
  box-shadow: none !important;
  border: 1px solid black; }

input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #eee;
  width: 90%;
  color: #fff;
  font-size: 22px;
  padding: 15px 5px 10px 5px; }
  input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5f738c;
    opacity: 1;
    /* Firefox */ }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5f738c;
    opacity: 1;
    /* Firefox */ }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #5f738c; }
  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #5f738c; }

.btn.disabled,
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed !important; }

.navbar {
  padding: 0px 70px 0 50px;
  height: 100px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  flex-wrap: wrap;
  /* <-- this is the fix */
  z-index: 99999;
  z-index: 1051;
  width: 100%;
  transition: all 0.1s ease; }
  @media (max-width: 992px) {
    .navbar {
      text-align: center;
      height: auto; } }

.navbar-brand {
  max-height: 64px;
  padding: 0; }
  .navbar-brand img {
    max-width: 100%;
    max-height: 64px; }
  @media (max-width: 992px) {
    .navbar-brand {
      width: 100%;
      margin-right: 0; } }

.header-line {
  width: 100vw;
  border-bottom: 3px solid;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 1050; }
  @media screen and (max-width: 991px) {
    .header-line {
      top: 80px; } }

.navbar-menu {
  display: flex;
  grid-gap: 12px;
  gap: 12px; }
  @media (max-width: 992px) {
    .navbar-menu {
      margin-top: 19px;
      width: 100%;
      grid-gap: 8px;
      gap: 8px; } }
  .navbar-menu-dark {
    color: #000; }
    .navbar-menu-dark img {
      filter: invert(1); }
  .navbar-menu-light {
    color: white; }

.scrolled {
  background: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); }
  .scrolled .navbar-menu-dark {
    color: #fff; }
    .scrolled .navbar-menu-dark img {
      filter: invert(0); }

.navbar-options {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px;
  background: rgba(217, 213, 213, 0.2);
  border-radius: 60px;
  white-space: nowrap; }
  .navbar-options span {
    font-weight: 600; }
  @media (max-width: 992px) {
    .navbar-options {
      font-size: 12px;
      padding: 8px 20px; } }
  .navbar-options:nth-child(2) {
    width: 100%; }
  .navbar-options-onlyxoxocode {
    background: #f15c22; }
    .navbar-options-onlyxoxocode span,
    .navbar-options-onlyxoxocode img {
      filter: invert(0); }

.navbar-language {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer; }
  .navbar-language img:nth-child(1) {
    width: 24px;
    height: 24px;
    margin-right: 10px; }
  .navbar-language img:nth-child(3) {
    margin-left: 10px; }
  .navbar-language-arrow-flip {
    transform: rotate(180deg); }

@media (max-width: 992px) {
  .navbar-nonmultilang.navbar-background {
    height: 81px; } }

@media (max-width: 992px) {
  .navbar-nonmultilang .navbar-brand {
    width: auto; } }

.navbar-nonmultilang .navbar-menu {
  justify-content: center; }
  @media (max-width: 992px) {
    .navbar-nonmultilang .navbar-menu {
      margin-top: 0px;
      width: auto; } }

.button-footer {
  padding-top: 20px;
  margin-top: 20px; }

.vshow {
  visibility: visible;
  visibility: initial; }

.vhide {
  visibility: hidden; }

.Toastify__toast-container.Toastify__toast-container--top-center {
  width: 35%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: left; }
  @media (max-width: 992px) {
    .Toastify__toast-container.Toastify__toast-container--top-center {
      width: 90%; } }

.Toastify__toast--success {
  background: #2b7336 !important;
  border-radius: 5px !important; }

.Toastify__toast-body {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
  padding-left: 36px; }
  @media (max-width: 992px) {
    .Toastify__toast-body {
      font-size: 12px;
      line-height: 22px; } }
  .Toastify__toast-body::before {
    content: "✓";
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    background-color: white;
    color: #2b7336;
    padding: 2px 6px;
    left: 0;
    border-radius: 50%;
    font-weight: 900; }

.Toastify__toast {
  margin-bottom: 0 !important;
  min-height: 100% !important;
  padding: 12px 12px !important; }

.Toastify__close-button {
  display: none !important; }

@media (max-width: 992px) {
  .language-switch-popup-container {
    background-color: #00000b8e;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; } }

.languages-popup {
  min-width: 290px;
  position: absolute;
  top: 60px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 25px 50px -5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px; }
  .languages-popup-title {
    padding: 8px 12px;
    font-weight: 500;
    font-size: 12px;
    color: #5f738c; }
  .languages-popup-option {
    padding: 8px 12px;
    font-weight: 400;
    font-size: 16px;
    color: #02020a;
    margin: 4px 0; }
    .languages-popup-option-selected {
      background-color: #f6f6f7; }
    .languages-popup-option:hover {
      background-color: #f6f6f7;
      border-radius: 8px; }
  @media (max-width: 992px) {
    .languages-popup {
      position: fixed;
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 332px;
      overflow: scroll; } }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
.fade {
  opacity: 0; }

.modal-dialog {
  max-width: 720px;
  border-radius: 8px; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; }

.fade.show {
  opacity: 1; }

.modal-content {
  background: transparent;
  color: #252a31;
  font-size: 14px;
  border: 0; }

.modal-footer {
  border: 0;
  justify-content: unset;
  font-size: 18px; }

.front_img_full_page {
  position: absolute;
  right: 0;
  bottom: 0; }

.modal-wrapper {
  background-color: #fff; }

.send-code-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 330px; }

.modal-send-code.show {
  animation: slide-right 0.5s forwards; }

.modal {
  top: 100px; }

.sendMailSuccess {
  padding: 15px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  font-size: 18px;
  font-weight: 600;
  z-index: 99999; }
  .sendMailSuccess span {
    background: #2b7336;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px; }

@keyframes slide-right {
  from {
    margin-left: 100%;
    width: 300%; }
  to {
    margin-left: 0%;
    width: 100%; } }

@keyframes slide {
  100% {
    left: 0; } }

.typing {
  display: inline-block;
  width: 92%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  animation: type 2.2s steps(120, start) alternate;
  color: #252a31; }

@keyframes type {
  from {
    width: 0; } }

.fw-500 {
  font-weight: 500; }

.fw-400 {
  font-weight: 400; }

.fs-16 {
  font-size: 16px; }

.fs-12 {
  font-size: 12px; }

.text-color-black {
  color: #252a31; }

.back-to-vouchers {
  width: -webkit-max-content;
  width: max-content;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: #252a31;
  color: #fff; }
  .back-to-vouchers:hover {
    background-color: #252a31 !important; }

.collect-voucher-card {
  padding: 40px;
  max-width: 720px;
  min-width: 700px;
  background-color: #fff;
  border-radius: 8px;
  margin: 30px 0; }
  @media screen and (max-width: 991px) {
    .collect-voucher-card {
      padding: 32px 16px;
      text-align: center; } }
  .collect-voucher-card .description {
    color: #5f738c;
    font-size: 14px;
    font-weight: 400; }
  .collect-voucher-card .card_logo {
    height: 136px; }
    @media screen and (max-width: 991px) {
      .collect-voucher-card .card_logo img {
        height: 120px;
        width: auto; } }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
#L4 {
  width: 100px;
  height: 29px;
  margin: auto;
  display: inline-block;
  text-align: center; }

.none {
  visibility: hidden; }

.clear {
  clear: both; }

.close {
  position: absolute;
  top: -10px;
  left: calc(50% - 15px);
  z-index: 99;
  opacity: 1;
  cursor: pointer; }
  .close img {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 999px;
    background-color: #fff; }
  .close :hover,
  .close :focus {
    cursor: pointer !important; }

.front_img {
  position: absolute;
  right: 20px;
  bottom: 20px;
  max-width: 190px; }

.card_logo {
  height: 160px;
  flex-basis: 240px;
  flex-grow: 0;
  flex-shrink: 0; }
  .card_logo img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover; }

.hooray {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px; }

.title-block {
  color: #252a31; }
  .title-block h2,
  .title-block h3,
  .title-block h4 {
    color: #252a31; }
  .title-block h2 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .title-block h2.price {
    font-size: 24px;
    margin: 0 0 10px 0;
    line-height: 28px;
    font-weight: 700;
    display: none; }
  .title-block h3 {
    border: 1px dashed rgba(255, 255, 255, 0.6);
    display: inline-block;
    padding: 10px 15px;
    margin: 0;
    font-size: 27px; }
  .title-block .validity {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #5f738c; }
  .title-block .description {
    margin-top: 22px;
    max-height: 120px;
    min-height: 120px; }

.modal-padding {
  padding: 40px; }

.grid {
  width: 100%;
  position: relative;
  border-radius: 4px; }

.grid-border {
  width: 100%;
  height: 100%; }

.grid-item {
  margin-right: 10px;
  margin-bottom: 20px;
  width: 240px;
  border-radius: 8px;
  background-position: center !important;
  background-size: cover !important; }
  .grid-item :hover {
    cursor: pointer; }

.equal-height {
  height: 224px; }

.inner-grid {
  border: 8px solid transparent;
  border-radius: 4px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.05); }
  .inner-grid:hover {
    border: 8px solid rgba(255, 255, 255, 0.2);
    transform: scale(1.05); }
  .inner-grid .card-parent {
    background: #fff;
    border-radius: 4px;
    overflow: hidden; }
  .inner-grid .title-block {
    height: 64px;
    padding: 20px 10px; }
    .inner-grid .title-block h2 {
      margin: 0; }

.selectedBlock {
  display: block;
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition-duration: 0.4s;
  position: absolute;
  height: 550px;
  width: 720px;
  left: auto;
  top: 0px;
  border-radius: 8px;
  padding: 40px;
  background: #fff; }
  .selectedBlock.xoxo-code {
    top: 100px;
    transition: transform 1s;
    transform-style: preserve-3d; }
  .selectedBlock .card-parent {
    display: flex; }
  .selectedBlock :hover {
    cursor: auto;
    transform: none; }
  .selectedBlock .title-block {
    height: auto;
    padding: 0; }
  .selectedBlock h2 {
    text-align: left;
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #252a31; }
  .selectedBlock h3 {
    padding: 0;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left; }
  .selectedBlock .validity {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Ink / Light */
    color: #5f738c;
    margin-bottom: 16px; }
  .selectedBlock .radio-buttons {
    justify-content: center;
    display: flex;
    flex-direction: row; }
  .selectedBlock .label {
    color: #252a31;
    margin-bottom: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; }
  .selectedBlock .card_logo {
    width: 240px; }
  .selectedBlock .front_img {
    max-width: 250px; }
  .selectedBlock .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: "Inter";
    color: #5f738c; }
  .selectedBlock hr {
    margin-top: 30px; }

.column-center {
  display: flex;
  justify-content: center; }

.blue-link {
  color: #0172cb !important; }

.collect_voucher_btn {
  position: absolute;
  bottom: 20px;
  width: 90%;
  padding-top: 40px;
  border-top: 1px solid #eee;
  color: white !important; }
  .collect_voucher_btn .btn {
    height: 56px;
    width: 480px;
    left: 80px;
    top: 0px;
    border-radius: 4px;
    padding: 12px 16px 8px 16px;
    background: #252a31;
    color: #fff !important; }

.divider {
  margin: 30px 0px; }

.btn {
  border-radius: 4px;
  padding: 12px 16px 8px 16px;
  background: #252a31;
  color: #fff; }

.btn-lg {
  width: 209px;
  padding: 9px 20px;
  font-size: 20px;
  margin: 20px 20px; }

.text-white {
  color: #fff; }

.inkNormal {
  color: #252a31; }

.tnc-link {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 4px;
  color: #5f738c !important; }
  .tnc-link div {
    display: inline-block; }

.mt-12 {
  margin-top: 12px !important; }

.voucher-code {
  border: 1px dashed #bac7d5;
  box-sizing: border-box;
  color: #252a31;
  background: transparent;
  text-align: left;
  border-radius: 4px;
  font-size: 18px;
  margin: 12px 12px;
  width: 480px;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .voucher-code span {
    word-break: break-all; }
  .voucher-code:focus {
    background: transparent;
    color: #fff;
    font-size: 24px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    outline: none; }

.secure-pin {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #252a31;
  margin-bottom: 24px; }

.copy-icon:hover {
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer !important; }

.flip1 {
  width: 0%;
  margin: auto;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  font-size: 50px;
  padding: 25px 0;
  color: white;
  overflow: hidden;
  transition: 1s all;
  animation-delay: 2s; }

.selectedBlock.flip1 {
  width: 100%;
  background: black;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: 0;
  left: 0;
  margin-left: 0;
  border-radius: 0;
  background: #0f0; }

.email-phone {
  display: flex;
  flex-flow: column-reverse; }
  .email-phone span {
    position: absolute;
    left: -23px; }
  .email-phone input:-ms-input-placeholder + label {
    text-align: left;
    cursor: text;
    overflow: hidden;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5); }
  .email-phone input:placeholder-shown + label {
    text-align: left;
    cursor: text;
    overflow: hidden;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5); }
  .email-phone ::-webkit-input-placeholder {
    -webkit-transition: inherit;
    transition: inherit; }
  .email-phone input:-internal-autofill-selected {
    background-color: transparent;
    background-image: none !important; }
  .email-phone input:focus::-webkit-input-placeholder {
    text-align: left; }
  .email-phone input:not(:-ms-input-placeholder) + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    text-align: left; }
  .email-phone input:not(:placeholder-shown) + label,
  .email-phone input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    text-align: left; }

.tnc-block {
  position: relative;
  z-index: 99999;
  padding: 40px;
  border-radius: 8px; }

.tnc-block {
  background-color: #fff;
  perspective: 1000px; }

.tnc-block-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

.tnc-block.show .tnc-block-inner {
  transform: rotateY(180deg); }

.tnc-block-front,
.tnc-block-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.tnc-block-front {
  background-color: #bbb;
  color: black; }

.tnc-block-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg); }

.cardBlock {
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  z-index: 1; }

.cardBlock .blocks {
  display: block;
  width: 100%;
  color: white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1199; }

.absolute {
  position: absolute; }

.cardBlock .back {
  transform: rotateY(180deg) translate(50%, 0%);
  width: 720px;
  right: -50%;
  position: relative; }

.cardBlock.flip {
  transform: rotateY(180deg); }

.brand-title {
  margin: auto;
  width: 60%;
  font-size: 20px;
  line-height: 31px; }

.brand-title ~ p {
  font-size: 20px; }

.go-to-top {
  display: none; }

.scrolling .go-to-top {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border-radius: 999px;
  bottom: 40px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  padding: 8px 24px;
  width: 140px;
  height: 36px;
  background: #000000;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1); }
  .scrolling .go-to-top:hover {
    cursor: pointer; }

.link {
  text-decoration: none;
  color: #5f738c;
  cursor: pointer !important; }
  .link :hover {
    cursor: pointer !important; }

.tnc-card {
  color: #333;
  transition: color 1500ms;
  background: #fff;
  border-radius: 8px;
  padding: 40px;
  position: relative;
  z-index: 999;
  overflow: auto;
  margin-bottom: 20px; }
  .tnc-card .tnc-content {
    height: 310px;
    overflow: auto;
    margin-bottom: 20px;
    font-size: 14px; }
    .tnc-card .tnc-content p {
      margin: 0;
      padding: 0; }
    .tnc-card .tnc-content h3.text-white {
      margin-bottom: 5px; }
    .tnc-card .tnc-content h3.tnc {
      margin-top: 30px; }
  .tnc-card .tnc-content-shadow {
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 25px 50px -5px rgba(0, 0, 0, 0.1); }
  .tnc-card .btn-lg {
    position: absolute;
    bottom: 22px;
    left: 37%; }
    .tnc-card .btn-lg:hover, .tnc-card .btn-lg:focus, .tnc-card .btn-lg:active, .tnc-card .btn-lg:active:hover, .tnc-card .btn-lg:active:focus {
      background-color: #818181;
      color: white !important; }

.tnc-cardModal {
  color: #252a31;
  height: 310px;
  overflow: auto;
  margin-bottom: 20px;
  font-size: 14px; }

/* This fires as soon as the element enters the dorm */
.tnc-card-transition-enter,
.tnc-card-transition-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0%;
  left: 49%;
  width: 0%;
  max-height: 500px;
  color: transparent;
  background-color: #333;
  position: relative; }

/* This is where we can add the transition*/
.tnc-card-transition-enter-active,
.tnc-card-transition-appear-active {
  left: 0%;
  right: 100%;
  width: 100%;
  max-height: 500px;
  background-color: #333;
  transition: all 400ms;
  position: relative; }

/* This fires as soon as the this.state.showList is false */
.tnc-card-transition-exit {
  left: 0%;
  width: 100%;
  right: 100%;
  max-height: 500px;
  background-color: #333;
  position: relative; }

/* fires as element leaves the DOM*/
.tnc-card-transition-exit-active {
  left: 49%;
  width: 0px;
  max-height: 500px;
  color: transparent;
  background-color: #333;
  transition: all 400ms;
  position: relative;
  visibility: hidden; }

.send_copy {
  color: #eee;
  transition: color 1500ms;
  background: red;
  border-radius: 4px;
  padding: 10%;
  margin: 0 5%; }

/* This fires as soon as the element enters the dorm */
.send_copy-enter,
.send_copy-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: green; }

/* This is where we can add the transition*/
.send_copy-enter-active,
.send_copy-appear-active {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: red;
  transition: all 400ms; }

/* This fires as soon as the this.state.showList is false */
.send_copy-exit {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: red; }

/* fires as element leaves the DOM*/
.send_copy-exit-active {
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: red;
  transition: all 400ms; }

@media (min-width: 991px) {
  .height-auto {
    height: calc(100vh - 220px); }
  .wrapper-padding {
    padding: 0px 60px; }
  .width-19 {
    width: 23.66%;
    height: auto; }
  .card4block {
    margin-left: 120px; }
    .card4block .selectedBlock {
      left: -5%; } }

@media (min-width: 1300px) {
  .width-19 {
    width: 19%; } }

.tnc-wrap {
  width: 720px;
  height: 400px;
  margin: 0 auto;
  top: 0px; }

.xc_support {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 0; }
  .xc_support .show-border {
    border-top: 1px solid #757575; }
  .xc_support .color-white {
    color: #ffffff; }
  .xc_support p {
    font-size: 16px;
    line-height: 24px;
    color: unset; }
  .xc_support h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: unset;
    margin: 8px 0px; }
  .xc_support ul {
    list-style-type: disc !important;
    padding-left: 2em !important; }
    .xc_support ul li {
      list-style: disc; }
  .xc_support-title {
    filter: invert(100%); }

.xc_mob_desc {
  overflow-y: auto;
  text-align: left !important; }

.xc_link {
  border-bottom: #0172cb 1px solid; }

.close-icon {
  position: absolute;
  top: -18px;
  right: 4px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9; }

.voucher-modal {
  background: #fff;
  padding: 40px 20px 20px 20px;
  position: relative; }
  .voucher-modal-text .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #252a31;
    margin-bottom: 4px; }
  .voucher-modal-text .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5f738c; }
  .voucher-modal .modal-footer {
    border-top: 1px solid #dee2e6 !important; }
  .voucher-modal .moda-header {
    border-bottom: none; }
  .voucher-modal .modal-content {
    overflow: visible; }
  .voucher-modal.modal-dialog-scrollable {
    overflow-y: visible !important; }
    .voucher-modal.modal-dialog-scrollable .modal-body {
      overflow-y: auto;
      max-height: calc(100vh - 330px) !important; }
    @media (max-width: 991px) {
      .voucher-modal.modal-dialog-scrollable .voucher-modal-body {
        overflow-y: auto;
        max-height: unset !important; } }
  @media (max-width: 991px) {
    .voucher-modal {
      margin-top: 16px !important;
      margin-bottom: 150px; } }

.voucher-modal-shadow {
  background: #ffffff;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 25px 50px -5px rgba(46, 45, 45, 0.1); }
  .voucher-modal-shadow .close-modal-btn {
    background-color: #fff;
    color: #252a31;
    padding: 8px 16px; }
    .voucher-modal-shadow .close-modal-btn:hover {
      background-color: #fff; }

.modal-text {
  width: 200px;
  padding: 10px 80px !important; }

.modal-dialog-scrollable {
  overflow-y: auto !important;
  max-height: none !important; }
  .modal-dialog-scrollable .modal-content {
    max-height: none !important; }

.validity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* Ink / Light */
  color: #5f738c;
  margin-bottom: 16px; }

.radio-buttons {
  justify-content: center;
  display: flex;
  flex-direction: row; }

.label {
  color: #252a31;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px; }

h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  letter-spacing: -0.5px;
  /* Ink / Normal */
  color: #252a31; }

.selected-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  /* Ink / Normal */
  color: #252a31; }

.enter-info-discription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  letter-spacing: -0.25px;
  /* Ink / Normal */
  color: #252a31;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px; }

.radio-inputs {
  margin-bottom: 20px; }

.btn-filled {
  /* Ink / Normal */
  background: #252a31;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  margin: 8px 0; }
  .btn-filled svg path {
    fill: #fff; }
  .btn-filled:disabled {
    /* Ink / Normal */
    cursor: not-allowed;
    background: #252a31;
    opacity: 0.6;
    border-radius: 4px; }
  .btn-filled:hover {
    color: white;
    opacity: 0.9; }

.text-error {
  color: #d21c1c !important; }

.without-border-bottom {
  border-bottom: none !important; }

.text-color-ink-light {
  color: #5f738c !important; }

input:focus {
  background: #ffffff;
  border: 1.5px solid #c7417b !important;
  border-radius: 4px; }

input[type="radio"] {
  border: 1.5px solid #5f738c !important; }

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out; }

input[type="radio"]:checked {
  transform: scale(1);
  border: 5.5px solid #c7417b !important; }

.class-list-modal {
  height: 100% !important;
  padding-bottom: 30px; }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
.redemption-modal {
  background-color: #fff;
  color: #000; }
  .redemption-modal-header {
    border-bottom: 0;
    padding: 7px 0 40px 0;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .redemption-modal-body {
    padding: 0px; }
  .redemption-modal ol {
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    color: #24292e; }
    .redemption-modal ol li {
      margin-bottom: 16px; }
  .redemption-modal .close {
    position: static; }
  .redemption-modal .checkbox {
    display: inline-block;
    margin: 34px 0;
    cursor: pointer; }
    .redemption-modal .checkbox img {
      margin-right: 14px; }
    .redemption-modal .checkbox span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6a737d; }
  .redemption-modal .modal-footer {
    padding: 16px;
    float: right;
    border-top: none !important;
    margin: -20px; }
    .redemption-modal .modal-footer .col {
      padding: 0; }
    .redemption-modal .modal-footer .cancel-btn {
      font-size: 14px;
      color: #24292e;
      margin-right: 20px; }
    .redemption-modal .modal-footer .continue-btn {
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      padding: 8px 16px;
      background-color: #252a31;
      border: none; }
      .redemption-modal .modal-footer .continue-btn:active {
        background-color: #252a31; }
      .redemption-modal .modal-footer .continue-btn:disabled {
        background-color: #eef0f2;
        color: #d1d5da; }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
.onlyxoxocode {
  background-image: url("/img/onlyxoxocode-background.png");
  background-repeat: no-repeat;
  background-size: auto 300px;
  background-position: center; }
  @media screen and (max-width: 991px) {
    .onlyxoxocode {
      background-size: 100% auto; } }
  .onlyxoxocode .heading {
    max-width: 744px;
    margin: 88px auto 48px auto;
    font-weight: 500;
    font-size: 32px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252a31; }
    @media screen and (max-width: 991px) {
      .onlyxoxocode .heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;
        margin: 27px auto 28px auto; } }
  .onlyxoxocode .highlight {
    color: #f15c22;
    font-size: 40px;
    display: inline-block; }
    @media screen and (max-width: 991px) {
      .onlyxoxocode .highlight {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px; } }
  .onlyxoxocode .features {
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    grid-gap: 20px;
    gap: 20px; }
    @media screen and (max-width: 991px) {
      .onlyxoxocode .features {
        flex-direction: column;
        width: 100%; } }
    .onlyxoxocode .features .feature {
      background: #ffffff;
      border: 1.5px solid #eff2f5;
      box-shadow: 0px 15px 20px rgba(36, 37, 47, 0.05);
      border-radius: 15px;
      padding: 24px; }
      .onlyxoxocode .features .feature .title {
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 8px; }
        .onlyxoxocode .features .feature .title .icon {
          background: #f5f7f9;
          border-radius: 14px;
          padding: 13px; }
          .onlyxoxocode .features .feature .title .icon img {
            width: 24px;
            height: 24px; }
        .onlyxoxocode .features .feature .title .text {
          margin-left: 16px;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: -0.25px; }
      .onlyxoxocode .features .feature .subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #5f738c; }
        .onlyxoxocode .features .feature .subtitle .bold {
          color: #252a31;
          display: inline-block; }
  .onlyxoxocode .buttons {
    margin-top: 112px;
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px; }
    @media screen and (max-width: 991px) {
      .onlyxoxocode .buttons {
        margin-top: 32px;
        margin-bottom: 32px; } }
    .onlyxoxocode .buttons .btn:hover {
      opacity: 0.8; }
    .onlyxoxocode .buttons .how-to-use {
      border: 1px solid #252a31;
      background-color: transparent;
      color: #252a31;
      padding: 16px 27px; }
      @media screen and (max-width: 991px) {
        .onlyxoxocode .buttons .how-to-use {
          padding: 16px; } }
    .onlyxoxocode .buttons .collect-voucher {
      background-color: #f15c22;
      padding: 16px 50px;
      border: none; }
      .onlyxoxocode .buttons .collect-voucher:active {
        background-color: #f15c22; }
      @media screen and (max-width: 991px) {
        .onlyxoxocode .buttons .collect-voucher {
          padding: 16px 34px; } }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
@media (max-width: 991px) {
  .close-icon {
    width: 32px;
    height: 32px;
    top: -40px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 999px;
    z-index: 90; }
  .height-auto {
    height: auto; }
  .vhide {
    display: none; }
  .vshow {
    display: flex; }
  .modal {
    height: auto;
    top: 140px; }
    .modal .selected-title {
      margin: 16px 0 4px 0;
      text-align: center; }
    .modal h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      text-align: center; }
    .modal .description,
    .modal .validity {
      text-align: center; }
  .modal-padding {
    padding: 16px; }
  .navbar {
    padding: 19px 16px; }
    .navbar .navbar-brand img {
      max-height: 43px; }
  .hooray {
    margin-top: 14px; }
  .float-label .input-send-copy {
    width: 100%; }
  .btn-send {
    width: 100%; }
  .grid-item {
    margin-right: 8px;
    margin-bottom: 16px; }
    .grid-item .title-block {
      height: 44px;
      padding: 12px 10px; }
      .grid-item .title-block h2 {
        margin: 0; }
    .grid-item:nth-child(even) {
      margin-right: 0; }
    .grid-item .close {
      left: 47%; }
    .grid-item .card_logo {
      width: 100%;
      height: auto; }
      .grid-item .card_logo img {
        height: 104px; }
    .grid-item.selectedBlock {
      width: 100%;
      left: 0%;
      min-height: 520px;
      top: 10px;
      margin: 0;
      padding: 16px; }
      .grid-item.selectedBlock .description {
        min-height: 100px;
        max-height: 100px; }
      .grid-item.selectedBlock .card-parent {
        display: block; }
      .grid-item.selectedBlock .card_logo {
        width: 180px;
        height: 120px;
        margin: auto; }
        .grid-item.selectedBlock .card_logo img {
          width: 100%;
          border-radius: 8px; }
      .grid-item.selectedBlock .title-block h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #252a31;
        display: block; }
      .grid-item.selectedBlock h2 {
        font-size: 16px;
        font-weight: 500;
        color: #252a31;
        text-align: center; }
      .grid-item.selectedBlock .validity {
        font-size: 14px;
        color: #5f738c;
        text-align: center; }
  .secure-pin {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
  .title-block .description {
    width: 100%;
    font-size: 14px;
    color: #5f738c;
    max-height: 90px;
    line-height: 20px;
    text-align: center; }
  .title-block h2 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px; }
  .title-block h2.price {
    font-size: 19px; }
  .collect_voucher_btn {
    width: 86%; }
    .collect_voucher_btn .btn {
      width: 100%;
      padding: 16px;
      position: static;
      height: 56px;
      background: #252a31;
      color: #fff; }
  .front_img {
    bottom: 10%;
    right: 10px;
    width: 90px; }
  .mob-center {
    justify-content: left; }
  .voucher-code {
    /* margin-left: 10px; */
    max-width: 100%;
    width: 480px; }
  .front_img_full_page {
    width: 44%;
    display: none; }
  .front_img_full_page_mobile {
    text-align: right; }
    .front_img_full_page_mobile img {
      width: 70%; }
  .modal-body {
    height: unset; }
  .cardBlock {
    top: 10px; }
    .cardBlock .blocks {
      width: 100%; }
  .brand-title {
    width: 98%; }
  .tnc-card {
    padding: 16px;
    height: 84vh; }
    .tnc-card .tnc-content {
      height: 84%; }
    .tnc-card .btn-lg {
      left: 18%; }
  .btn-lg {
    font-size: 16px; }
  .link {
    font-size: 14px; }
  .modal-dialog {
    margin-top: 0; }
  .modal-wrapper {
    margin: 0;
    height: 100%;
    border-radius: 0; }
  .modal-wrapper.error-modal {
    margin: 80px 16px;
    border-radius: 4px;
    height: auto; }
  .modal-wrapper.other-modal {
    margin: 80px 16px;
    border-radius: 4px;
    height: auto; }
    .modal-wrapper.other-modal .modal-content {
      height: 100%;
      overflow: auto; }
  .send-code-wrap {
    height: 100%;
    width: 100%;
    margin-top: 90px; }
  .tnc-wrap {
    width: 100%;
    height: 100%; }
    .tnc-wrap .tnc-block {
      width: 100%;
      height: 84vh;
      padding: 16px 8px; }
  .tnc-cardModal {
    height: 84%; }
  .send-copy-btn {
    width: 100%; } }

@media (max-width: 667px) {
  .grid-item {
    width: 47%;
    max-height: 152px;
    margin-right: 8px;
    margin-bottom: 16px; }
  .mob-center {
    padding-left: 10px;
    padding-bottom: 2rem;
    justify-content: left; } }

@media (max-width: 321px) {
  .grid-item {
    width: 140px;
    max-height: 152px;
    margin-right: 16px;
    margin-bottom: 16px; } }

.landingPage {
  height: 100vh; }
  .landingPage .redeemBtn {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    line-height: 24px; }
    .landingPage .redeemBtn.mobile {
      font-size: 14px; }
  .landingPage .howToLink {
    font-size: 16px;
    cursor: pointer;
    color: #0172cb; }
    .landingPage .howToLink:hover {
      text-decoration: underline; }
  .landingPage .landingDescription {
    font-size: 24px;
    color: #fff;
    line-height: 32px; }
    .landingPage .landingDescription.mobile {
      font-size: 14px; }

.howToRedeemInfo {
  color: #252a31;
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translate(0, -50%) !important; }
  @media (max-width: 992px) {
    .howToRedeemInfo {
      max-width: 375px;
      transform: unset !important; } }
  .howToRedeemInfo .modal-content {
    width: 720px;
    background-color: #fff; }
    @media (max-width: 992px) {
      .howToRedeemInfo .modal-content {
        width: 375px; } }
  .howToRedeemInfo .modalTitle {
    padding: 20px 40px; }
    .howToRedeemInfo .modalTitle button {
      position: relative;
      top: 50%; }
    .howToRedeemInfo .modalTitle .modal-title {
      font-size: 32px;
      font-weight: 600;
      letter-spacing: -1px; }
      @media (max-width: 992px) {
        .howToRedeemInfo .modalTitle .modal-title {
          font-size: 18px;
          letter-spacing: -0.25px; } }
  .howToRedeemInfo .modalBody {
    padding: 20px 40px 60px; }
    .howToRedeemInfo .modalBody .redemptionSteps .step {
      color: #252a31; }
      .howToRedeemInfo .modalBody .redemptionSteps .step .list {
        line-height: 24px; }
        .howToRedeemInfo .modalBody .redemptionSteps .step .list .iconBackground {
          height: 26px;
          width: 26px;
          background-color: #252a31;
          border-radius: 50%; }
          @media (max-width: 992px) {
            .howToRedeemInfo .modalBody .redemptionSteps .step .list .iconBackground {
              height: 24px;
              width: 24px; } }
          .howToRedeemInfo .modalBody .redemptionSteps .step .list .iconBackground svg {
            position: relative;
            left: 50%;
            transform: translate(-50%, 0%); }
        .howToRedeemInfo .modalBody .redemptionSteps .step .list .title {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: -0.25px;
          margin-bottom: 2px;
          margin-top: 0px; }
          @media (max-width: 992px) {
            .howToRedeemInfo .modalBody .redemptionSteps .step .list .title {
              font-size: 16px; } }
        .howToRedeemInfo .modalBody .redemptionSteps .step .list .subTitle {
          font-size: 16px; }
          @media (max-width: 992px) {
            .howToRedeemInfo .modalBody .redemptionSteps .step .list .subTitle {
              font-size: 14px; } }
        .howToRedeemInfo .modalBody .redemptionSteps .step .list .vertical {
          border-left: 1px solid #bac7d5;
          height: 22px;
          width: 0px;
          margin: 12px auto; }
          @media (max-width: 992px) {
            .howToRedeemInfo .modalBody .redemptionSteps .step .list .vertical {
              height: 18px; } }
    .howToRedeemInfo .modalBody hr {
      border-top: 1px solid #eeeeee; }
    .howToRedeemInfo .modalBody .notesSection .label {
      font-size: 12px;
      font-weight: 500; }

.funLayout {
  overflow-y: auto; }
  .funLayout .backGroundImg {
    background-size: cover !important;
    height: 45%; }
  .funLayout .bottomPart {
    min-height: 60%; }
    .funLayout .bottomPart .content {
      background-color: #fff;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin: 0 15%;
      bottom: 60px;
      height: inherit; }
      .funLayout .bottomPart .content .logoHeader img {
        width: 120px;
        max-height: 40px;
        max-width: 120px;
        margin-top: 24px;
        object-fit: contain; }
      .funLayout .bottomPart .content.mobile {
        margin: 0 16px;
        padding-left: 20px;
        padding-right: 20px; }
        .funLayout .bottomPart .content.mobile .logoHeader img {
          width: 96px; }
      .funLayout .bottomPart .content .layoutTitle {
        font-size: 56px;
        font-family: "Josefin Sans", sans-serif;
        line-height: normal;
        padding-top: 40px; }
        .funLayout .bottomPart .content .layoutTitle.mobile {
          font-size: 24px;
          line-height: 24px; }
      .funLayout .bottomPart .content .landingDescription {
        font-size: 20px;
        line-height: 32px; }
        .funLayout .bottomPart .content .landingDescription.mobile {
          font-size: 14px; }

.casualLayout .mobileLogo {
  height: 56px;
  padding: 12px 16px; }
  .casualLayout .mobileLogo img {
    width: 96px;
    max-height: 32px;
    object-fit: contain; }

.casualLayout .backGroundImg {
  background-size: cover !important;
  border-radius: 8px 0px 0px 0px; }
  .casualLayout .backGroundImg.mobile {
    border-radius: 0px; }

.casualLayout .content {
  padding: 44px 40px; }
  .casualLayout .content img {
    width: 120px;
    max-height: 32px;
    object-fit: contain; }
  .casualLayout .content.mobile {
    padding: 20px 16px; }
  .casualLayout .content .layoutTitle {
    font-size: 56px;
    font-family: "Playfair Display", serif;
    line-height: normal;
    margin-top: 40px;
    letter-spacing: 0.5px; }
    .casualLayout .content .layoutTitle.mobile {
      font-size: 24px;
      line-height: 24px;
      margin-top: 0px; }

.grandLayout .landingHeader {
  padding: 20px 0px; }
  .grandLayout .landingHeader img {
    width: 120px;
    max-height: 32px;
    object-fit: contain; }
  .grandLayout .landingHeader.mobile {
    padding: 12px 0px; }
    .grandLayout .landingHeader.mobile img {
      width: 96px; }

.grandLayout .previewBody {
  background-size: cover !important;
  height: calc(100% - 72px); }
  .grandLayout .previewBody.noLogo {
    height: 100vh; }
  .grandLayout .previewBody.mobile {
    height: calc(100% - 50px); }
    .grandLayout .previewBody.mobile.noLogo {
      height: 100vh; }
  .grandLayout .previewBody .content {
    padding-top: 80px;
    background-color: transparent !important;
    overflow-y: auto;
    padding-bottom: 48px;
    margin: 0 10%;
    scrollbar-width: 0px; }
    .grandLayout .previewBody .content::-webkit-scrollbar {
      width: 0px; }
    .grandLayout .previewBody .content .layoutTitle {
      font-size: 80px;
      font-family: "Cinzel", serif;
      line-height: normal; }
      .grandLayout .previewBody .content .layoutTitle.mobile {
        font-size: 36px;
        line-height: 32px; }
    .grandLayout .previewBody .content .howToLink {
      color: #fff !important; }
      .grandLayout .previewBody .content .howToLink:hover {
        text-decoration: underline; }

.simpleLayout {
  overflow-y: auto; }
  .simpleLayout .backGroundImg {
    background-size: cover !important; }
  .simpleLayout .previewBody {
    background-color: #fff;
    border-radius: 8px;
    margin: 0 15%;
    top: 50%;
    transform: translate(0%, -50%);
    max-height: 75%;
    overflow-y: auto;
    scrollbar-width: 2px; }
    .simpleLayout .previewBody::-webkit-scrollbar {
      width: 2px; }
    .simpleLayout .previewBody.mobile {
      margin: 0 20px;
      width: unset; }
    .simpleLayout .previewBody .logoHeader {
      padding: 28px 0px;
      text-align: center;
      background-color: #f8f8f8;
      border-radius: 8px 8px 0px 0px;
      position: -webkit-sticky;
      position: sticky;
      top: 0px; }
      .simpleLayout .previewBody .logoHeader img {
        width: 120px;
        max-height: 40px;
        max-width: 120px;
        object-fit: contain; }
      .simpleLayout .previewBody .logoHeader.mobile {
        padding: 12px 0px; }
        .simpleLayout .previewBody .logoHeader.mobile img {
          width: 96px; }
    .simpleLayout .previewBody .content {
      padding: 20px 20px 40px;
      height: unset !important; }
      .simpleLayout .previewBody .content .layoutTitle {
        font-size: 40px;
        font-family: "Manrope", sans-serif;
        line-height: 55px;
        letter-spacing: 0.5px; }
        .simpleLayout .previewBody .content .layoutTitle.mobile {
          font-size: 24px;
          line-height: 32px; }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
.verify-mobile {
  max-width: 720px;
  margin-top: 140px;
  background-color: #fff; }
  .verify-mobile_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: #252a31; }
  .verify-mobile_subtitle {
    margin: 32px auto;
    max-width: 560px; }
    .verify-mobile_subtitle a {
      color: #0172cb; }
  .verify-mobile_tnc {
    font-size: 12px;
    line-height: 16px;
    color: #5f738c;
    cursor: pointer; }

/*---------------------------
|  cardGradient
-------------------*/
/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */
/***** Line Height ****/
/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */
/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */
.content {
  background-color: white;
  height: 100vh;
  padding-top: 50px; }

.box {
  text-align: center;
  background-color: white;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #e8edf1; }
  @media screen and (max-width: 991px) {
    .box {
      width: 90%; } }

.paymentWrapper {
  background-color: lightgrey;
  width: 100%;
  margin-top: 70px; }

.title {
  margin-top: 10px;
  margin-bottom: 10px; }
  .title .titleattribute {
    color: black;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    font-weight: 600; }

.sub-title {
  color: #5f738c;
  font-size: 16px;
  line-height: 24px;
  text-align: center; }

.email-title {
  color: #252a31;
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  font-weight: 500; }

.email-subtext {
  font-size: 14px;
  line-height: 20px;
  color: #5f738c;
  margin-bottom: 25px; }

.input-email {
  width: 100%;
  height: 40px;
  border: 1px solid #bac7d5;
  color: black;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 2px;
  margin-top: 5px; }
  @media screen and (max-width: 991px) {
    .input-email {
      width: 90%; } }

.testEmail {
  background-color: #ebebe4;
  cursor: not-allowed; }

.email-lable {
  color: black; }
  @media screen and (max-width: 991px) {
    .email-lable {
      width: 90%; } }

.submit-Button {
  background-color: #c7417b;
  color: #ffffff;
  width: 150px;
  height: 44px;
  border-radius: 5px; }

.submit-Button-coloumn {
  margin-top: 20px;
  margin-bottom: 30px; }

.email {
  text-align: left;
  width: 35%;
  margin: 0 auto; }
  @media screen and (max-width: 991px) {
    .email {
      width: 90%; } }

.poweredby {
  font-size: 14px;
  line-height: 20px;
  font-family: Rubik;
  color: #5f738c; }

.without-border-bottom-otp {
  border-bottom: none !important; }
  .without-border-bottom-otp .modal-title {
    line-height: 1; }
  .without-border-bottom-otp .heading {
    line-height: 20px; }
  .without-border-bottom-otp .subText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5f738c; }
  .without-border-bottom-otp .back-icon {
    top: -6px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: white;
    border-radius: 50%;
    cursor: pointer; }

.claim-btn-filled {
  /* Ink / Normal */
  background: #c7417b;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  margin: 8px 0; }
  .claim-btn-filled svg path {
    fill: #fff; }
  .claim-btn-filled:disabled {
    /* Ink / Normal */
    cursor: not-allowed;
    background: #c7417b;
    opacity: 0.6;
    border-radius: 4px; }
  .claim-btn-filled:hover {
    color: white;
    opacity: 0.9; }

.error {
  color: #970c0c;
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px; }

