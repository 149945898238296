@import "../../../styles/variables";
.fade {
  //   transform: scale(0);
  opacity: 0;
  //   -webkit-transition: all .35s linear;
  //   -o-transition: all .35s linear;
  //   transition: all .35s linear;
}
.modal-dialog {
  max-width: 720px;
  border-radius: 8px;
  //height: 480px; //send copy height
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //   background-color: rgba(0,0,0,0.9); /*dim the background*/
}
.fade.show {
  opacity: 1;
  //   transform: scale(1);
  //   background-color: transparent;
}
.modal-content {
  background: transparent;
  color: $inkNormal;
  font-size: 14px;
  border: 0;
}
.modal-footer {
  border: 0;
  justify-content: unset;
  font-size: 18px;
}
.front_img_full_page {
  position: absolute;
  right: 0;
  bottom: 0;
}
.modal-body {
  // height: 74vh;
}

.modal-wrapper {
  //   max-width: 100% !important;
  //   margin:0 !important;
  //   padding:0 !important;
  //   .modal-content{
  //     height: 100vh;
  //     overflow: auto;
  //   }
  background-color: #fff;
}
.send-code-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 330px;
}

.modal-send-code.show {
  -webkit-animation: slide-right 0.5s forwards;
  // -webkit-animation-delay: 1s;
  animation: slide-right 0.5s forwards;
  // animation-delay: 1s;
}

.modal {
  top: 100px;
}
.sendMailSuccess {
  // height     : 44px;
  // height        : 52px;
  padding: 15px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  font-size: 18px;
  font-weight: 600;
  z-index: 99999;

  span {
    background: #2b7336;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
  }
}

@-webkit-keyframes slide-right {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}
@-moz-keyframes slide-right {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}
@-o-keyframes slide-right {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}
@keyframes slide-right {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0px;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.typing {
  display: inline-block;
  width: 92%;
  // white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation: type 2.2s steps(120, end) alternate;
  animation: type 2.2s steps(120, start) alternate;
  color: $inkNormal;
}

@keyframes type {
  from {
    width: 0;
  }
}

@-webkit-keyframes type {
  from {
    width: 0;
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.text-color-black {
  color: $inkNormal;
}

.back-to-vouchers {
  width: max-content;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: $inkNormal;
  color: #fff;
  &:hover {
    background-color: $inkNormal !important;
  }
}
.collect-voucher-card {
  padding: 40px;
  max-width: 720px;
  min-width: 700px;
  background-color: #fff;
  border-radius: 8px;
  margin: 30px 0;
  @media screen and (max-width: 991px) {
    padding: 32px 16px;
    text-align: center;
    // max-width: calc(100vw - 32px);
  }
  .description {
    color: #5f738c;
    font-size: 14px;
    font-weight: 400;
  }
  .card_logo {
    height: 136px;
    img {
      @media screen and (max-width: 991px) {
        height: 120px;
        width: auto;
      }
    }
  }
}
